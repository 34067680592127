<template>
  <div>
    <div
      class="relative bg-white pt-16 pb-8 lg:pb-32 overflow-hidden lg:py-24 lg:mt-12"
    >
      <div class="max-w-3xl mx-auto text-center mb-12 mt-6">
        <h2 class="blob text-3xl font-extrabold text-gray-900">What We Do</h2>
        <p class="mt-4 text-lg text-gray-500 px-4">
          The most successful companies have something in common. They all put
          data at the core of their organizations. As a trusted partner, we'll
          help you transform into a data-driven business.
        </p>
      </div>

      <div class="relative">
        <div
          class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
        >
          <div
            class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0"
          >
            <div>
              <div>
                <span
                  class="h-12 w-12 rounded-md flex items-center justify-center bg-yellow-400"
                >
                  <!-- Heroicon name: outline/puzzle -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="#FACC15"
                    viewBox="0 0 24 24"
                    stroke="white"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h2
                  id="anchor-strategy"
                  class="text-3xl font-extrabold tracking-tight text-gray-900"
                >
                  Data Strategy
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  We'll help you develop and implement a strategy to use data
                  wisely. Whether you are taking your first steps in the world
                  of analytics or have already implemented advanced systems. We
                  will work together to find the path that best suits what you
                  are looking for.
                </p>
                <div class="mt-6">
                  <a
                    target="_blank"
                    href="https://calendly.com/rositano-tony/30-minute-meeting"
                    class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-400 hover:bg-indigo-700"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
            <!-- <div class="mt-8 border-t border-gray-200 pt-6">
                            <blockquote>
                                <div>
                                    <p class="text-base text-gray-500">
                                        &ldquo;Cras velit quis eros eget rhoncus
                                        lacus ultrices sed diam. Sit orci risus
                                        aenean curabitur donec aliquet. Mi
                                        venenatis in euismod ut.&rdquo;</p>
                                </div>
                                <footer class="mt-3">
                                    <div class="flex items-center space-x-3">
                                        <div class="flex-shrink-0">
                                            <img class="h-6 w-6 rounded-full"
                                                src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                                alt="">
                                        </div>
                                        <div
                                            class="text-base font-medium text-gray-700">
                                            Marcia Hill, Digital Marketing
                                            Manager</div>
                                    </div>
                                </footer>
                            </blockquote>
                        </div> -->
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0">
            <div
              class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
            >
              <img
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="@/assets/img/img-strategy.jpg"
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-24">
        <div
          class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
        >
          <div
            class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2"
          >
            <div>
              <div>
                <span
                  class="h-12 w-12 rounded-md flex items-center justify-center bg-yellow-400"
                >
                  <!-- Heroicon name: outline/presentation-chart-line -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h2
                  id="anchor-tracking"
                  class="text-3xl font-extrabold tracking-tight text-gray-900"
                >
                  Web tracking
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Gain greater insight into your site’s performance and identify
                  opportunities for growth. We can help you implement Google
                  Analytics and Google Tag Manager based on the needs of your
                  business. Activities such as clicks, scroll tracking, cart
                  abandonment are just some of the many possibilities that are
                  available with Google Tag Manager.
                </p>
                <div class="mt-6">
                  <a
                    target="_blank"
                    href="https://calendly.com/rositano-tony/30-minute-meeting"
                    class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-400 hover:bg-indigo-700"
                  >
                    Setup your analytics
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div
              class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
            >
              <img
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="@/assets/img/img-web-tracking.jpg"
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-24 relative">
        <div
          class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
        >
          <div
            class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0"
          >
            <div>
              <div>
                <span
                  class="h-12 w-12 rounded-md flex items-center justify-center bg-yellow-400"
                >
                  <!-- Heroicon name: outline/sparkles -->
                  <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h2
                  id="anchor-dashboard"
                  class="text-3xl font-extrabold tracking-tight text-gray-900"
                >
                  Beautiful dashboards and reports
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Data visualizations help you bring your insights to life. We
                  turn your data into interactive and intuitive dashboards. We
                  connect to any 3rd-party data service and centralize
                  everything into one place to make reporting and analysis much
                  simpler.
                </p>
                <div class="mt-6">
                  <a
                    target="_blank"
                    href="https://calendly.com/rositano-tony/30-minute-meeting"
                    class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-400 hover:bg-indigo-700"
                  >
                    Start centralising data
                  </a>
                </div>
              </div>
            </div>
            <!-- <div class="mt-8 border-t border-gray-200 pt-6">
                            <blockquote>
                                <div>
                                    <p class="text-base text-gray-500">
                                        &ldquo;Cras velit quis eros eget rhoncus
                                        lacus ultrices sed diam. Sit orci risus
                                        aenean curabitur donec aliquet. Mi
                                        venenatis in euismod ut.&rdquo;</p>
                                </div>
                                <footer class="mt-3">
                                    <div class="flex items-center space-x-3">
                                        <div class="flex-shrink-0">
                                            <img class="h-6 w-6 rounded-full"
                                                src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                                alt="">
                                        </div>
                                        <div
                                            class="text-base font-medium text-gray-700">
                                            Marcia Hill, Digital Marketing
                                            Manager</div>
                                    </div>
                                </footer>
                            </blockquote>
                        </div> -->
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0">
            <div
              class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
            >
              <img
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="@/assets/img/img-beautiful-dash.png"
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.blob {
  width: 100%;
  transform: translate3d(0, 0, 35px) perspective(100px);
}

.blob:before {
  position: absolute;
  content: "";
  background: linear-gradient(217deg, #facc15, #f7ecc2);

  width: 70px;
  height: 70px;
  display: block;

  top: -20px;
  animation: border-transform 6s linear infinite;
  z-index: -1;

  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;
}

@keyframes border-transform {
  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }

  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }

  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }

  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }

  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }

  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }

  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}
</style>
